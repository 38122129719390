import axios from 'axios'
import qs from 'qs'

export const baseURL = `${window.location.protocol}//service.cancon.com.cn`

const buriedPointEnity = axios.create({
  baseURL: baseURL,
  timeout: 5000
})

buriedPointEnity.interceptors.request.use(
  config => {
    config.headers.token = localStorage.getItem('token')
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

export default (res) => {
  buriedPointEnity({
    url: '/api/fmo/setMessage',
    method: 'post',
    data: qs.stringify({
      text: JSON.stringify(res.data),
      psnname: JSON.stringify(res.config),
      flowname: JSON.stringify(res.headers),
      parentnodename: JSON.stringify(res.status),
      parenttext: JSON.stringify(res.statusText),
      clienty: JSON.stringify(res.message),
      clientx: localStorage.getItem('userName'),
      width: JSON.stringify(res.stack),
      height: JSON.stringify(res.response)
    })
  })
}
