import axios from 'axios'
import router from '@/router'
import qs from 'qs'
import dayjs from 'dayjs'
import { ElLoading, ElMessage } from 'element-plus'
import buriedPoint from './buriedPoint'
// axios.defaults.withCredentials = true // 允许携带cookie

export const baseURL = `${window.location.protocol}//service.cancon.com.cn`

const instance = axios.create({
  baseURL: baseURL,
  timeout: 999 * 1000
})
export function getCookie (name) {
  const strcookie = document.cookie
  const arrcookie = strcookie.split('; ')
  for (let i = 0; i < arrcookie.length; i++) {
    const arr = arrcookie[i].split('=')
    if (arr[0] === name) {
      return arrcookie[i].split(`${name}=`)[1]
    }
  }
  return ''
}

if (!document.referrer.includes(location.origin) && !localStorage.getItem('token')) {
  localStorage.setItem('token', getCookie('DP_Token'))
}
let loading = {}
// 请求拦截器
instance.interceptors.request.use(
  config => {
    loading = ElLoading.service({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.2)'
    })
    config.headers.token = localStorage.getItem('token')
    config.headers.businessScope = sessionStorage.getItem('businessScope')
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截器
instance.interceptors.response.use(res => {
  loading.close()
  sessionStorage.setItem('serversDate', dayjs(new Date(res.headers.date)).format('YYYY-MM-DD HH:mm:ss'))
  if (res.data.code) {
    // eslint-disable-next-line eqeqeq
    if (res.data.code != '200') {
      console.log(res)
      ElMessage({
        message: res.data.msg,
        type: 'error'
      })
    }
  }
  buriedPoint(res)
  return res.data
}, err => {
  loading.close()
  // 判断响应状态码是403，清除本地的用户信息，跳转至登录页面
  if (err.response && err.response.status === 401) {
    localStorage.removeItem('token')
    if (sessionStorage.getItem('redirect') !== '/index') {
      sessionStorage.setItem('redirect', router.currentRoute._rawValue.fullPath)
    }
    router.push('/login')
  } else {
    buriedPoint(err)
  }
  return Promise.reject(err)
})

/**
  *json字符串传参请求API
  * @param {String} url -接口地址
  * @param {String} method -请求方式
  * @param {Object} data -请求传参
*/
export const request = (url, method, data, contentType) => {
  const config = {}
  if (method.toUpperCase() === 'GET') {
    config.params = data
  } else {
    config.data = data
  }
  if (contentType) {
    config.headers = { 'Content-Type': contentType }
  }
  return instance({
    url,
    method,
    ...config
  })
}

/**
  *x-www-form-urlencoded传参请求API
  *@param {String}url - 接口地址
  * @param {String} method -请求方式
  * @param {Object} data -请求传参
 （post,put,patch）键值对传参采用
*/

// 表单提交
export const requestForm = (url, method, data) => {
  const config = {}
  config.data = qs.stringify(data)
  config.headers = {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded'
  }
  return instance({
    url,
    method,
    ...config
  })
}

// 上传文件所用
export const requesFile = (url, method, data) => {
  const config = {}
  config.data = data
  config.headers = {
    'Content-Type': 'multipart/form-data'
  }
  return instance({
    url,
    method,
    ...config
  })
}

// 下载文件所用 (2022.9.1 因请求415 头部增加extraData参数=>EvaluationQuery.vue  EvaluationDetails.vue )
export const exportForm = (url, method, data, extraData) => {
  const config = {}
  if (extraData) {
    config.data = data
  } else {
    config.data = qs.stringify(data)
  }
  config.headers = {
    token: localStorage.getItem('token')
  }
  if (extraData) {
    config.headers['Content-Type'] = 'application/json'
  }
  config.responseType = 'blob'
  return axios({
    url: baseURL + url,
    method,
    ...config
  })
}

// form认证
export const certificationForm = (This, ref) => {
  let ps = false
  This.$refs[ref].validate((vs) => {
    ps = !vs
  })
  return ps
}

// 深拷贝
export function deepClone (obj) {
  var objClone = Array.isArray(obj) ? [] : {}
  if (obj && typeof obj === 'object') {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (obj[key] && typeof obj[key] === 'object') {
          objClone[key] = deepClone(obj[key])
        } else {
          objClone[key] = obj[key]
        }
      }
    }
  }
  return objClone
}

// 下载文件所用 (2022.9.1 因请求415 头部增加extraData参数=>EvaluationQuery.vue  EvaluationDetails.vue )
export const exportFormBody = (url, method, data) => {
  const config = {}
  config.headers = {
    token: localStorage.getItem('token')
  }
  config.responseType = 'blob'
  return axios({
    url: baseURL + url,
    method,
    ...config,
    data
  })
}
